<template>
<v-app>
    <div class="d-flex justify-start" style="width:100%;">
        <div :style="!$vuetify.breakpoint.xs?'min-width:200px;':''">
            <drawer
                :drawer="drawer"
                :sidebarColor="sidebarColor"
                :sidebarTheme="sidebarTheme"
                :items="drawerItems"
                @goHome="$router.push({name: '홈화면'})">
            </drawer>
        </div>
        <div style="width:100%">
            <app-bar
                background="bg-transparent"
                has-bg
                @drawer-toggle="toggleDrawer($event)"
                :toggle-active="drawer"
                :navbarFixed="navbarFixed"></app-bar>
            <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in">
                <router-view></router-view>
            </fade-transition>
        </div>
    </div>
</v-app>
</template>

<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import {
    FadeTransition
} from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";

export default {
    components: {
        FadeTransition,
        Drawer,
        AppBar,
    },
    data() {
        return {
            drawer: null,
            drawerItems: [{
                    title: '홈',
                    items: [{
                        title: "홈화면",
                        link: "/pages/dashboard/dashboard",
                        icon: "fas fa-home",
                        active: false,
                    }],
                }, {
                    title: '조제 및 약국관리',
                    items: [{
                        title: "조제현황",
                        link: "/pages/dashboard/data-manager",
                        icon: "fas fa-clipboard-list",
                        active: false,
                    }, {
                        title: "정산내역",
                        link: "/pages/dashboard/payment",
                        icon: "fas fa-money-check-alt",
                        active: false,
                    }, {
                        title: "약국관리",
                        icon: "fas fa-hospital",
                        active: false,
                        items: [{
                            title: "약국 정보 수정",
                            link: "/pages/dashboard/pharmacy-info",
                            active: false,
                        },{
                            title: "약사 관리",
                            link: "/pages/dashboard/pharmacists",
                            active: false,
                        }, {
                            title: "약국 상품 관리",
                            link: "/pages/dashboard/products",
                            active: false,
                        }, ],
                    }],
                },
                {
                    title: '기타',
                    items: [{
                        title: "마이페이지",
                        icon: "fas fa-user-cog",
                        active: false,
                        items: [{
                            title: "개인정보 수정",
                            link: "/pages/dashboard/my-info",
                            active: false,
                        }],
                    }, {
                        title: "고객센터",
                        icon: "fas fa-question-circle",
                        link: "/pages/dashboard/customer-services",
                        active: false,
                    }],
                },
            ],
            showSettingsDrawer: false,
            sidebarColor: "success",
            sidebarTheme: "transparent",
            navbarFixed: false,
        };
    },
    methods: {
        initScrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                initScrollbar("sidenav");
            }
        },
        toggleSettingsDrawer(value) {
            this.showSettingsDrawer = value;
        },
        updateSidebarColor(value) {
            this.sidebarColor = value;
        },
        updateSidebarTheme(value) {
            this.sidebarTheme = value;
        },
        toggleNavbarPosition(value) {
            this.navbarFixed = value;
        },
        toggleDrawer(event) {
            this.$nextTick(() => {
                this.drawer = false;
                this.$nextTick(() => {
                    this.drawer = event;
                });
            });
        },
    },
    async mounted() {
        this.initScrollbar();
    },
};
</script>

<style lang="scss"></style>
