// [Pharmacy]
// String id;
// DateTime createAt;
// String accountId;
// String accountUserName;
// DateTime accountUserBirth;
// String accountUserPhone;
// String accountUserEmail;
// String pharmacistLicenseImageUrl;
// int numOfFavorites;
// String name;
// String address;
// String addressDetail;
// double gpsX;
// double gpsY;
// String phone;
// String fax;
// bool mondayEnable;
// bool tuesdayEnable;
// bool wednesdayEnable;
// bool thursdayEnable;
// bool fridayEnable;
// bool saturdayEnable;
// bool sundayEnable;
// DateTime mondayOpenAt;
// DateTime tuesdayOpenAt;
// DateTime wednesdayOpenAt;
// DateTime thursdayOpenAt;
// DateTime fridayOpenAt;
// DateTime saturdayOpenAt;
// DateTime sundayOpenAt;
// DateTime mondayCloseAt;
// DateTime tuesdayCloseAt;
// DateTime wednesdayCloseAt;
// DateTime thursdayCloseAt;
// DateTime fridayCloseAt;
// DateTime saturdayCloseAt;
// DateTime sundayCloseAt;
// bool availableAtHoliday;
// List<dynamic> pharmacyImages;
// String businessLicenseFileUrl;
// String accountBankName;
// String accountNumber;
// String accountOwnerName;
// String invoiceEmail;
// List<dynamic> tagList;
// String businessNumber;
// String settlementPersonName;
// String settlementPersonPhone;
// DateTime mondayLunchStartAt;
// DateTime tuesdayLunchStartAt;
// DateTime wednesdayLunchStartAt;
// DateTime thursdayLunchStartAt;
// DateTime fridayLunchStartAt;
// DateTime saturdayLunchStartAt;
// DateTime sundayLunchStartAt;
// DateTime mondayLunchEndAt;
// DateTime tuesdayLunchEndAt;
// DateTime wednesdayLunchEndAt;
// DateTime thursdayLunchEndAt;
// DateTime fridayLunchEndAt;
// DateTime saturdayLunchEndAt;
// DateTime sundayLunchEndAt;
// List<dynamic> operatingTimes;
// bool mondayLunch;
// bool tuesdayLunch;
// bool wednesdayLunch;
// bool thursdayLunch;
// bool fridayLunch;
// bool saturdayLunch;
// bool sundayLunch;
// String postCode;
// List<dynamic> holidayWeekDays;
// String phone1;
// String phone2;
// String phone3;
// String fax1;
// String fax2;
// String fax3;
// String settlementPersonPhone1;
// String settlementPersonPhone2;
// String settlementPersonPhone3;
// String accountUserPhone1;
// String accountUserPhone2;
// String accountUserPhone3;
// bool quit;
// any whyQuit;
// String approveState;
// bool kakaoTalkIdNotification;
// String kakaoTalkId;
// bool shopOpened;
// bool isAddressJibun;
// String gender;
// String pharmacyNumber;
// bool enableInDay;
// bool enableQuick;
// bool enableMail;
// List<dynamic> readQnaAnswers;
// List<dynamic> readNotices;
// bool prescriptionFax;
// bool prescriptionEmail;
// [PharmacyWork]
// String id;
// DateTime createAt;
// any guideSaved;
// any priceSaved;
// String whyCancel;
// DeliveryState deliveryState;
// DeliveryType deliveryType;
// String address;
// String addressDetail;
// String addressAdditionalInfo;
// any requestPharmacyProducts;
// String patientId;
// String patientName;
// String patientGender;
// DateTime patientBirth;
// String patientPhone;
// String prescriptionFileUrl;
// String prescriptionPharmacyFileUrl;
// int paymentAmount;
// int settleAmount;
// int paidAmount;
// bool refunded;
// DateTime payAt;
// String receiptImageUrl;
// String hospitalId;
// String hospitalWorkId;
// String hospitalName;
// String doctorId;
// String doctorName;
// String pharmacyId;
// String pharmacyName;
// String pharmacyWorkerName;
// String pharmacyAddress;
// String patientUserMemo;
// String name;
// String guide;
// bool morning;
// bool lunch;
// bool dinner;
// bool night;
// TakeMedicineTime takeMedicineTime;
// int takeMedicineTimeAfterOrBefore;
// int timesPerDay;
// int numOfdays;
// int priceMedicine;
// int priceEtc;
// int priceDelivery;
// int priceNoTax;
// DateTime makingMedicineAt;
// String pharmacyMemo;
// String trackingNumber;
// String deliveryPersonPhone;
// bool isSettled;
// DateTime settledAt;
// String pharmacistId;
// bool deliveryCancel;
// String deliveryCancelReason;
// String deliveryCancelType;
// bool payFail;
// DateTime pickupExpectedAt;
// String billingKey;
// [PharmacyProduct]
// String id;
// DateTime createAt;
// String name;
// String description;
// List<dynamic> imageUrls;
// int originalPrice;
// double discountRate;
// int finalPrice;
// int numOfStocks;
// int orderPriority;
// bool enable;
// int count;
// [AdminNotice]
// String id;
// DateTime createAt;
// AdminNoticeTarget adminNoticeTarget;
// String title;
// String content;
// [Qna]
// String id;
// DateTime createAt;
// QuestionerType questionerType;
// any appUserId;
// any appUserName;
// any hospitalId;
// any hospitalName;
// any hospitalWorkerId;
// any hospitalWorkerName;
// String pharmacyId;
// String pharmacyName;
// any pharmacyWorkerId;
// any pharmacyWorkerName;
// String category;
// String title;
// String content;
// List<dynamic> questionAttachmentUrls;
// bool isReplied;
// String reply;
// DateTime replyAt;
// String replyImageUrls;
// String replyDocUrls;
// [Pharmacist]
// String id;
// DateTime createAt;
// String name;
// String pharmacistLicenseImageUrl;
// String profileImageUrl;
// [TempHoliday]
// String id;
// DateTime createAt;
// String hospitalId;
// String pharmacyId;
// DateTime date;
// String name;
// [Manual]
// String id;
// DateTime createAt;
// String type;
// String title;
// String content;
// List<dynamic> imageUrls;
// List<dynamic> docUrls;
// List<dynamic> movieUrls;
// List<dynamic> youtubeUrls;

/* eslint-disable no-unused-vars */
import Vue from "vue";
export default {
};

function isNull(text) {
	if ((typeof text == "undefined") || (text == null)) {
		return true;
	}
	else {
		return false;
	}
}

function toNumber(text) {
	if (isNull(text)) {
		return null;
	}
	else {
		return text * 1;
	}
}

function toText(number) {
	if (isNull(number)) {
		return null;
	}
	else {
		return number + '';
	}
}

function toSafeValue(value) {
	if (isNull(value)) {
		return null;
	}
	else {
		return value;
	}
}

export const models = {
	install(Vue) {
		Vue.prototype.$models = {
			PharmacyModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						accountId: '',
						accountUserName: '',
						accountUserBirth: null,
						accountUserPhone: '',
						accountUserEmail: '',
						pharmacistLicenseImageUrl: '',
						numOfFavorites: 0,
						name: '',
						address: '',
						addressDetail: '',
						gpsX: 0,
						gpsY: 0,
						phone: '',
						fax: '',
						mondayEnable: null,
						tuesdayEnable: null,
						wednesdayEnable: null,
						thursdayEnable: null,
						fridayEnable: null,
						saturdayEnable: null,
						sundayEnable: null,
						mondayOpenAt: null,
						tuesdayOpenAt: null,
						wednesdayOpenAt: null,
						thursdayOpenAt: null,
						fridayOpenAt: null,
						saturdayOpenAt: null,
						sundayOpenAt: null,
						mondayCloseAt: null,
						tuesdayCloseAt: null,
						wednesdayCloseAt: null,
						thursdayCloseAt: null,
						fridayCloseAt: null,
						saturdayCloseAt: null,
						sundayCloseAt: null,
						availableAtHoliday: null,
						pharmacyImages: null,
						businessLicenseFileUrl: '',
						accountBankName: '',
						accountNumber: '',
						accountOwnerName: '',
						invoiceEmail: '',
						tagList: null,
						businessNumber: '',
						settlementPersonName: '',
						settlementPersonPhone: '',
						mondayLunchStartAt: null,
						tuesdayLunchStartAt: null,
						wednesdayLunchStartAt: null,
						thursdayLunchStartAt: null,
						fridayLunchStartAt: null,
						saturdayLunchStartAt: null,
						sundayLunchStartAt: null,
						mondayLunchEndAt: null,
						tuesdayLunchEndAt: null,
						wednesdayLunchEndAt: null,
						thursdayLunchEndAt: null,
						fridayLunchEndAt: null,
						saturdayLunchEndAt: null,
						sundayLunchEndAt: null,
						operatingTimes: null,
						mondayLunch: null,
						tuesdayLunch: null,
						wednesdayLunch: null,
						thursdayLunch: null,
						fridayLunch: null,
						saturdayLunch: null,
						sundayLunch: null,
						postCode: '',
						holidayWeekDays: null,
						phone1: '',
						phone2: '',
						phone3: '',
						fax1: '',
						fax2: '',
						fax3: '',
						settlementPersonPhone1: '',
						settlementPersonPhone2: '',
						settlementPersonPhone3: '',
						accountUserPhone1: '',
						accountUserPhone2: '',
						accountUserPhone3: '',
						quit: null,
						whyQuit: null,
						approveState: '',
						kakaoTalkIdNotification: null,
						kakaoTalkId: '',
						shopOpened: null,
						isAddressJibun: null,
						gender: '',
						pharmacyNumber: '',
						enableInDay: null,
						enableQuick: null,
						enableMail: null,
						readQnaAnswers: null,
						readNotices: null,
						prescriptionFax: null,
						prescriptionEmail: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						accountId: toText(model.accountId),
						accountUserName: toText(model.accountUserName),
						accountUserBirth: toSafeValue(model.accountUserBirth),
						accountUserPhone: toText(model.accountUserPhone),
						accountUserEmail: toText(model.accountUserEmail),
						pharmacistLicenseImageUrl: toText(model.pharmacistLicenseImageUrl),
						numOfFavorites: toNumber(model.numOfFavorites),
						name: toText(model.name),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						gpsX: toNumber(model.gpsX),
						gpsY: toNumber(model.gpsY),
						phone: toText(model.phone),
						fax: toText(model.fax),
						mondayEnable: toSafeValue(model.mondayEnable),
						tuesdayEnable: toSafeValue(model.tuesdayEnable),
						wednesdayEnable: toSafeValue(model.wednesdayEnable),
						thursdayEnable: toSafeValue(model.thursdayEnable),
						fridayEnable: toSafeValue(model.fridayEnable),
						saturdayEnable: toSafeValue(model.saturdayEnable),
						sundayEnable: toSafeValue(model.sundayEnable),
						mondayOpenAt: toSafeValue(model.mondayOpenAt),
						tuesdayOpenAt: toSafeValue(model.tuesdayOpenAt),
						wednesdayOpenAt: toSafeValue(model.wednesdayOpenAt),
						thursdayOpenAt: toSafeValue(model.thursdayOpenAt),
						fridayOpenAt: toSafeValue(model.fridayOpenAt),
						saturdayOpenAt: toSafeValue(model.saturdayOpenAt),
						sundayOpenAt: toSafeValue(model.sundayOpenAt),
						mondayCloseAt: toSafeValue(model.mondayCloseAt),
						tuesdayCloseAt: toSafeValue(model.tuesdayCloseAt),
						wednesdayCloseAt: toSafeValue(model.wednesdayCloseAt),
						thursdayCloseAt: toSafeValue(model.thursdayCloseAt),
						fridayCloseAt: toSafeValue(model.fridayCloseAt),
						saturdayCloseAt: toSafeValue(model.saturdayCloseAt),
						sundayCloseAt: toSafeValue(model.sundayCloseAt),
						availableAtHoliday: toSafeValue(model.availableAtHoliday),
						pharmacyImages: toSafeValue(model.pharmacyImages),
						businessLicenseFileUrl: toText(model.businessLicenseFileUrl),
						accountBankName: toText(model.accountBankName),
						accountNumber: toText(model.accountNumber),
						accountOwnerName: toText(model.accountOwnerName),
						invoiceEmail: toText(model.invoiceEmail),
						tagList: toSafeValue(model.tagList),
						businessNumber: toText(model.businessNumber),
						settlementPersonName: toText(model.settlementPersonName),
						settlementPersonPhone: toText(model.settlementPersonPhone),
						mondayLunchStartAt: toSafeValue(model.mondayLunchStartAt),
						tuesdayLunchStartAt: toSafeValue(model.tuesdayLunchStartAt),
						wednesdayLunchStartAt: toSafeValue(model.wednesdayLunchStartAt),
						thursdayLunchStartAt: toSafeValue(model.thursdayLunchStartAt),
						fridayLunchStartAt: toSafeValue(model.fridayLunchStartAt),
						saturdayLunchStartAt: toSafeValue(model.saturdayLunchStartAt),
						sundayLunchStartAt: toSafeValue(model.sundayLunchStartAt),
						mondayLunchEndAt: toSafeValue(model.mondayLunchEndAt),
						tuesdayLunchEndAt: toSafeValue(model.tuesdayLunchEndAt),
						wednesdayLunchEndAt: toSafeValue(model.wednesdayLunchEndAt),
						thursdayLunchEndAt: toSafeValue(model.thursdayLunchEndAt),
						fridayLunchEndAt: toSafeValue(model.fridayLunchEndAt),
						saturdayLunchEndAt: toSafeValue(model.saturdayLunchEndAt),
						sundayLunchEndAt: toSafeValue(model.sundayLunchEndAt),
						operatingTimes: toSafeValue(model.operatingTimes),
						mondayLunch: toSafeValue(model.mondayLunch),
						tuesdayLunch: toSafeValue(model.tuesdayLunch),
						wednesdayLunch: toSafeValue(model.wednesdayLunch),
						thursdayLunch: toSafeValue(model.thursdayLunch),
						fridayLunch: toSafeValue(model.fridayLunch),
						saturdayLunch: toSafeValue(model.saturdayLunch),
						sundayLunch: toSafeValue(model.sundayLunch),
						postCode: toText(model.postCode),
						holidayWeekDays: toSafeValue(model.holidayWeekDays),
						phone1: toText(model.phone1),
						phone2: toText(model.phone2),
						phone3: toText(model.phone3),
						fax1: toText(model.fax1),
						fax2: toText(model.fax2),
						fax3: toText(model.fax3),
						settlementPersonPhone1: toText(model.settlementPersonPhone1),
						settlementPersonPhone2: toText(model.settlementPersonPhone2),
						settlementPersonPhone3: toText(model.settlementPersonPhone3),
						accountUserPhone1: toText(model.accountUserPhone1),
						accountUserPhone2: toText(model.accountUserPhone2),
						accountUserPhone3: toText(model.accountUserPhone3),
						quit: toSafeValue(model.quit),
						whyQuit: toSafeValue(model.whyQuit),
						approveState: toText(model.approveState),
						kakaoTalkIdNotification: toSafeValue(model.kakaoTalkIdNotification),
						kakaoTalkId: toText(model.kakaoTalkId),
						shopOpened: toSafeValue(model.shopOpened),
						isAddressJibun: toSafeValue(model.isAddressJibun),
						gender: toText(model.gender),
						pharmacyNumber: toText(model.pharmacyNumber),
						enableInDay: toSafeValue(model.enableInDay),
						enableQuick: toSafeValue(model.enableQuick),
						enableMail: toSafeValue(model.enableMail),
						readQnaAnswers: toSafeValue(model.readQnaAnswers),
						readNotices: toSafeValue(model.readNotices),
						prescriptionFax: toSafeValue(model.prescriptionFax),
						prescriptionEmail: toSafeValue(model.prescriptionEmail),
					};
				},
			},

			PharmacyWorkModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						guideSaved: null,
						priceSaved: null,
						whyCancel: '',
						deliveryState: null,
						deliveryType: null,
						address: '',
						addressDetail: '',
						addressAdditionalInfo: '',
						requestPharmacyProducts: null,
						patientId: '',
						patientName: '',
						patientGender: '',
						patientBirth: null,
						patientPhone: '',
						prescriptionFileUrl: '',
						prescriptionPharmacyFileUrl: '',
						paymentAmount: 0,
						settleAmount: 0,
						paidAmount: 0,
						refunded: null,
						payAt: null,
						receiptImageUrl: '',
						hospitalId: '',
						hospitalWorkId: '',
						hospitalName: '',
						doctorId: '',
						doctorName: '',
						pharmacyId: '',
						pharmacyName: '',
						pharmacyWorkerName: '',
						pharmacyAddress: '',
						patientUserMemo: '',
						name: '',
						guide: '',
						morning: null,
						lunch: null,
						dinner: null,
						night: null,
						takeMedicineTime: null,
						takeMedicineTimeAfterOrBefore: 0,
						timesPerDay: 0,
						numOfdays: 0,
						priceMedicine: 0,
						priceEtc: 0,
						priceDelivery: 0,
						priceNoTax: 0,
						makingMedicineAt: null,
						pharmacyMemo: '',
						trackingNumber: '',
						deliveryPersonPhone: '',
						isSettled: null,
						settledAt: null,
						pharmacistId: '',
						deliveryCancel: null,
						deliveryCancelReason: '',
						deliveryCancelType: '',
						payFail: null,
						pickupExpectedAt: null,
						billingKey: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						guideSaved: toSafeValue(model.guideSaved),
						priceSaved: toSafeValue(model.priceSaved),
						whyCancel: toText(model.whyCancel),
						deliveryState: toSafeValue(model.deliveryState),
						deliveryType: toSafeValue(model.deliveryType),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						addressAdditionalInfo: toText(model.addressAdditionalInfo),
						requestPharmacyProducts: toSafeValue(model.requestPharmacyProducts),
						patientId: toText(model.patientId),
						patientName: toText(model.patientName),
						patientGender: toText(model.patientGender),
						patientBirth: toSafeValue(model.patientBirth),
						patientPhone: toText(model.patientPhone),
						prescriptionFileUrl: toText(model.prescriptionFileUrl),
						prescriptionPharmacyFileUrl: toText(model.prescriptionPharmacyFileUrl),
						paymentAmount: toNumber(model.paymentAmount),
						settleAmount: toNumber(model.settleAmount),
						paidAmount: toNumber(model.paidAmount),
						refunded: toSafeValue(model.refunded),
						payAt: toSafeValue(model.payAt),
						receiptImageUrl: toText(model.receiptImageUrl),
						hospitalId: toText(model.hospitalId),
						hospitalWorkId: toText(model.hospitalWorkId),
						hospitalName: toText(model.hospitalName),
						doctorId: toText(model.doctorId),
						doctorName: toText(model.doctorName),
						pharmacyId: toText(model.pharmacyId),
						pharmacyName: toText(model.pharmacyName),
						pharmacyWorkerName: toText(model.pharmacyWorkerName),
						pharmacyAddress: toText(model.pharmacyAddress),
						patientUserMemo: toText(model.patientUserMemo),
						name: toText(model.name),
						guide: toText(model.guide),
						morning: toSafeValue(model.morning),
						lunch: toSafeValue(model.lunch),
						dinner: toSafeValue(model.dinner),
						night: toSafeValue(model.night),
						takeMedicineTime: toSafeValue(model.takeMedicineTime),
						takeMedicineTimeAfterOrBefore: toNumber(model.takeMedicineTimeAfterOrBefore),
						timesPerDay: toNumber(model.timesPerDay),
						numOfdays: toNumber(model.numOfdays),
						priceMedicine: toNumber(model.priceMedicine),
						priceEtc: toNumber(model.priceEtc),
						priceDelivery: toNumber(model.priceDelivery),
						priceNoTax: toNumber(model.priceNoTax),
						makingMedicineAt: toSafeValue(model.makingMedicineAt),
						pharmacyMemo: toText(model.pharmacyMemo),
						trackingNumber: toText(model.trackingNumber),
						deliveryPersonPhone: toText(model.deliveryPersonPhone),
						isSettled: toSafeValue(model.isSettled),
						settledAt: toSafeValue(model.settledAt),
						pharmacistId: toText(model.pharmacistId),
						deliveryCancel: toSafeValue(model.deliveryCancel),
						deliveryCancelReason: toText(model.deliveryCancelReason),
						deliveryCancelType: toText(model.deliveryCancelType),
						payFail: toSafeValue(model.payFail),
						pickupExpectedAt: toSafeValue(model.pickupExpectedAt),
						billingKey: toText(model.billingKey),
					};
				},
			},

			PharmacyProductModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						description: '',
						imageUrls: null,
						originalPrice: 0,
						discountRate: 0,
						finalPrice: 0,
						numOfStocks: 0,
						orderPriority: 0,
						enable: null,
						count: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						description: toText(model.description),
						imageUrls: toSafeValue(model.imageUrls),
						originalPrice: toNumber(model.originalPrice),
						discountRate: toNumber(model.discountRate),
						finalPrice: toNumber(model.finalPrice),
						numOfStocks: toNumber(model.numOfStocks),
						orderPriority: toNumber(model.orderPriority),
						enable: toSafeValue(model.enable),
						count: toNumber(model.count),
					};
				},
			},

			AdminNoticeModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						adminNoticeTarget: null,
						title: '',
						content: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						adminNoticeTarget: toSafeValue(model.adminNoticeTarget),
						title: toText(model.title),
						content: toText(model.content),
					};
				},
			},

			QnaModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						questionerType: null,
						appUserId: null,
						appUserName: null,
						hospitalId: null,
						hospitalName: null,
						hospitalWorkerId: null,
						hospitalWorkerName: null,
						pharmacyId: '',
						pharmacyName: '',
						pharmacyWorkerId: null,
						pharmacyWorkerName: null,
						category: '',
						title: '',
						content: '',
						questionAttachmentUrls: null,
						isReplied: null,
						reply: '',
						replyAt: null,
						replyImageUrls: '',
						replyDocUrls: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						questionerType: toSafeValue(model.questionerType),
						appUserId: toSafeValue(model.appUserId),
						appUserName: toSafeValue(model.appUserName),
						hospitalId: toSafeValue(model.hospitalId),
						hospitalName: toSafeValue(model.hospitalName),
						hospitalWorkerId: toSafeValue(model.hospitalWorkerId),
						hospitalWorkerName: toSafeValue(model.hospitalWorkerName),
						pharmacyId: toText(model.pharmacyId),
						pharmacyName: toText(model.pharmacyName),
						pharmacyWorkerId: toSafeValue(model.pharmacyWorkerId),
						pharmacyWorkerName: toSafeValue(model.pharmacyWorkerName),
						category: toText(model.category),
						title: toText(model.title),
						content: toText(model.content),
						questionAttachmentUrls: toSafeValue(model.questionAttachmentUrls),
						isReplied: toSafeValue(model.isReplied),
						reply: toText(model.reply),
						replyAt: toSafeValue(model.replyAt),
						replyImageUrls: toText(model.replyImageUrls),
						replyDocUrls: toText(model.replyDocUrls),
					};
				},
			},

			PharmacistModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						pharmacistLicenseImageUrl: '',
						profileImageUrl: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						pharmacistLicenseImageUrl: toText(model.pharmacistLicenseImageUrl),
						profileImageUrl: toText(model.profileImageUrl),
					};
				},
			},

			TempHolidayModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						hospitalId: '',
						pharmacyId: '',
						date: null,
						name: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						hospitalId: toText(model.hospitalId),
						pharmacyId: toText(model.pharmacyId),
						date: toSafeValue(model.date),
						name: toText(model.name),
					};
				},
			},

			ManualModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						type: '',
						title: '',
						content: '',
						imageUrls: null,
						docUrls: null,
						movieUrls: null,
						youtubeUrls: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						type: toText(model.type),
						title: toText(model.title),
						content: toText(model.content),
						imageUrls: toSafeValue(model.imageUrls),
						docUrls: toSafeValue(model.docUrls),
						movieUrls: toSafeValue(model.movieUrls),
						youtubeUrls: toSafeValue(model.youtubeUrls),
					};
				},
			},
		};
	}
};

Vue.use(models);
