import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import '../plugins/firebase';
import firebase from 'firebase/compat/app';

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const DataManager = () => import("../views/Dashboard/DataManager.vue");
const Payment = () => import("../views/Dashboard/Payment.vue");
const HistoryInfo = () => import("../views/Dashboard/HistoryInfo.vue");
const PharmacyInfo = () => import("../views/Dashboard/PharmacyInfo.vue");
const Quit = () => import("../views/Dashboard/Quit.vue");
const Pharmacists = () => import("../views/Dashboard/Pharmacists.vue");
const Products = () => import("../views/Dashboard/Products.vue");
const MyInfo = () => import("../views/Dashboard/MyInfo.vue");
const CustomerServices = () => import("../views/Dashboard/CustomerServices.vue");
const CustomerServicesTemp = () => import("../views/Dashboard/CustomerServicesTemp.vue");
const SignIn = () => import("../views/Auth/SignIn.vue");
const SignUp = () => import("../views/Auth/SignUp.vue");
const SignUpMyInfo = () => import("../views/Auth/SignUpMyInfo.vue");
const SignUpPharmacy = () => import("../views/Auth/SignUpPharmacy.vue");
const FindId = () => import("../views/Auth/FindId.vue");
const FindPw = () => import("../views/Auth/FindPw.vue");
const PopupView = () => import("../views/Dashboard/PopupView.vue");
const HistoryInfoRedirect = () => import("../views/Dashboard/HistoryInfoRedirect.vue");
const ActivateUser = () => import("../views/Auth/ActivateUser.vue");
const DormantPharmacy = () => import("../views/Auth/DormantPharmacy.vue");
const ChangePasswordInformation = () => import("../views/Dashboard/ChangePasswordInformation.vue");
const PrintPage = () => import("../views/Dashboard/PrintPage.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: {
      requireAuth: true
    },
    name: "Dashboard",
    component: DashboardLayout,
    redirect: "/pages/dashboard/dashboard",
    children: [
      {
        path: "/pages/dashboard/dashboard",
        name: "홈화면",
        component: Dashboard,
      },
      {
        path: "/pages/dashboard/data-manager",
        name: "조제현황",
        component: DataManager,
        meta: {
          groupName: "조제 및 약국관리",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/data-manager/data-view",
            name: "접수정보",
            component: HistoryInfo,
            meta: {
              groupName: "조제 및 약국관리",
              secondGroupName: "조제현황",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/data-manager/data-view-redirect",
            name: "접수정보보기",
            component: HistoryInfoRedirect,
            meta: {
              groupName: "조제 및 약국관리",
              secondGroupName: "조제현황",
            },
            props: true,
          }
        ],
      },
      {
        path: "/pages/dashboard/payment",
        name: "정산내역",
        component: Payment,
        meta: {
          groupName: "조제 및 약국관리",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/pharmacy-info",
        name: "약국 정보 수정",
        component: PharmacyInfo,
        meta: {
          groupName: "조제 및 약국관리",
          secondGroupName: "약국관리",
        },
      },
      {
        path: "/pages/dashboard/quit",
        name: "탈퇴하기",
        component: Quit,
        meta: {
          groupName: "조제 및 약국관리",
          secondGroupName: "약국관리",
          thirdGroupName: "약국 정보 수정",
        },
      },
      {
        path: "/pages/dashboard/pharmacists",
        name: "약사 관리",
        component: Pharmacists,
        meta: {
          groupName: "조제 및 약국관리",
          secondGroupName: "약국관리",
        },
      },
      {
        path: "/pages/dashboard/products",
        name: "약국 상품 관리",
        component: Products,
        meta: {
          groupName: "조제 및 약국관리",
          secondGroupName: "약국관리",
        },
      },
      {
        path: "/pages/dashboard/my-info",
        name: "개인정보 수정",
        component: MyInfo,
        meta: {
          groupName: "기타",
          secondGroupName: "마이페이지",
        },
      },
      {
        path: "/pages/dashboard/customer-services",
        name: "고객센터",
        component: CustomerServices,
        meta: {
          groupName: "기타",
        },
        props: true,
      },
      {
        path: "/pages/dashboard/customer-services-temp",
        name: "고객센터",
        component: CustomerServicesTemp,
        meta: {
          requireAuth: true,
          groupName: "기타",
        },
        props: true,
      },
    ],
  },
  {
    path: "/pages/dashboard/popup-view",
    name: "팝업",
    component: PopupView,
  },
  {
    path: "/pages/dashboard/print-page",
    name: "프린트",
    component: PrintPage,
    props: true,
  },
  {
    path: '/',
    name: "Auth",
    redirect: "/pages/auth/signin",
    component: AuthBasicLayout,
    children: [
      {
        path: "/pages/auth/signin",
        name: "로그인",
        component: SignIn,
        props: true,
      },
      {
        path: "/pages/auth/signup",
        name: "회원가입",
        component: SignUp,
      },
      {
        path: "/pages/auth/signup-myinfo",
        name: "회원가입 기본정보입력",
        component: SignUpMyInfo,
        props: true,
      },
      {
        path: "/pages/auth/signup-pharmacy",
        name: "회원가입 약국정보입력",
        component: SignUpPharmacy,
        props: true,
      },
      {
        path: "/pages/auth/findid",
        name: "아이디 찾기",
        component: FindId,
      },
      {
        path: "/pages/auth/findpw",
        name: "비밀번호 찾기",
        component: FindPw,
      },
      {
        path: "/pages/auth/activate-user",
        name: "재가입",
        component: ActivateUser,
        props: true,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/auth/dormant-pharmacy",
        name: "휴면해제",
        component: DormantPharmacy,
        props: true,
        meta: {
          name: '휴면해제',
          requireAuth: true,
          props: true
        },
      },
    ],
  },
  {
    path: "/pages/change-password-information",
    name: "비밀번호 변경 안내",
    component: ChangePasswordInformation,
    meta: {
      requireAuth: false
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let nextPath = null;
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (firebase.auth().currentUser == null) {
      nextPath = '/pages/auth/signin';
    }
  }

  if (nextPath == null) {
    if (firebase.auth().currentUser != null) {
      let userStatusDatabaseRef = firebase.database().ref('/pharmacy_user_status/' + firebase.auth().currentUser.uid);
      let isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        screen: to.path,
      };
      userStatusDatabaseRef.update(isOnlineForDatabase);
    }
    next();
  }
  else {
    if (firebase.auth().currentUser != null) {
      let userStatusDatabaseRef = firebase.database().ref('/pharmacy_user_status/' + firebase.auth().currentUser.uid);
      let isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        screen: nextPath,
      };
      userStatusDatabaseRef.update(isOnlineForDatabase);
    }
    next({ path: nextPath });
  }
});

export default router;
