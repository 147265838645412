// Pharmacy:pharmacys

import Vue from "vue";
import firebase from "firebase/compat/app";
import moment from 'moment';

export default {
};

export const database = {
	install(Vue) {
		Vue.prototype.$database = {
			currentTimestamp: function () {
				return firebase.firestore.Timestamp.now();
			},
			dateToTimestamp: function (date) {
				return firebase.firestore.Timestamp.fromDate(new Date(date));
			},
			timestampToDate: function (timestamp) {
				return timestamp.toDate();
			},
			onPharmacys: function (onSnapshot) {
				return firebase.firestore()
					.collection('pharmacys')
					.onSnapshot(onSnapshot);
			},
			loadPharmacys: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacys')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacy: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPharmacy: async function (model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(model.id)
					.set(model);
			},
			editPharmacy: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.update(model);
			},
			deletePharmacy: async function (id) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.delete();
			},
			setPharmacy: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.set(model);
			},
			onPharmacyWorksByDate: function (pharmacyId, startDate, endDate, onSnapshot) {
				return firebase.firestore()
					.collection('pharmacyWorks')
					.where('pharmacyId', '==', pharmacyId)
					.where('createAt', '>=', startDate)
					.where('createAt', '<=', endDate)
					.orderBy('createAt', 'desc')
					.onSnapshot(onSnapshot);
			},
			onPharmacyWorks: function (pharmacyId,
				startDate,
				endDate,
				patientNameFilter,
				hospitalNameFilter,
				deliveryStateFilter,
				onSnapshot) {
				let ref = firebase.firestore().collection('pharmacyWorks')
					.where('pharmacyId', '==', pharmacyId)
					.where('deliveryState', 'in', deliveryStateFilter);
				if ((startDate != null) && (endDate != null)) {
					ref = ref.where('createAt', '>=', startDate)
						.where('createAt', '<=', endDate);
				}
				if (patientNameFilter.length > 0) {
					ref = ref.where('patientName', '==', patientNameFilter);
				}
				if (hospitalNameFilter.length > 0) {
					ref = ref.where('hospitalName', '==', hospitalNameFilter);
				}
				return ref.orderBy('createAt', 'desc').onSnapshot(onSnapshot);
			},
			loadPharmacyWorks: async function (
				pharmacyId,
				startDate,
				endDate,
				patientNameFilter,
				hospitalNameFilter,
				deliveryStateFilter) {
				let itemList = [];
				let ref = firebase.firestore().collection('pharmacyWorks')
					.where('pharmacyId', '==', pharmacyId)
					.where('deliveryState', 'in', deliveryStateFilter);
				if ((startDate != null) && (endDate != null)) {
					ref = ref.where('createAt', '>=', startDate)
						.where('createAt', '<=', endDate);
				}
				if (patientNameFilter.length > 0) {
					ref = ref.where('patientName', '==', patientNameFilter);
				}
				if (hospitalNameFilter.length > 0) {
					ref = ref.where('hospitalName', '==', hospitalNameFilter);
				}
				await ref.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadNewPharmacyWorks: async function (
				pharmacyId, lastDoc, updatedLastDoc) {
				let itemList = [];
				let ref = firebase.firestore().collection('pharmacyWorks')
					.where('pharmacyId', '==', pharmacyId)
					.where('deliveryState', '==', 'DeliveryState.waitingForPay')
					.orderBy('createAt', 'asc');
				if (lastDoc != null) {
					ref = ref.startAfter(lastDoc);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
							updatedLastDoc(querySnapshot.docs[i]);
						}
					});
				return itemList;
			},
			loadPharmacyWorks2: async function (
				pharmacyId,
				purchaseStartDate,
				purchaseEndDate,
				paymentStartDate,
				paymentEndDate,
				patientNameFilter,
				hospitalNameFilter,
				isSettledFilter,
				orderBy) {
				let itemList = [];
				let ref = firebase.firestore().collection('pharmacyWorks')
					.where('pharmacyId', '==', pharmacyId)
				if ((purchaseStartDate != null) && (purchaseEndDate != null)) {
					ref = ref.where('payAt', '>=', purchaseStartDate)
						.where('payAt', '<=', purchaseEndDate);
				}
				if ((paymentStartDate != null) && (paymentEndDate != null)) {
					ref = ref.where('settledAt', '>=', paymentStartDate)
						.where('settledAt', '<=', paymentEndDate);
				}
				if (patientNameFilter.length > 0) {
					ref = ref.where('patientName', '==', patientNameFilter);
				}
				if (hospitalNameFilter.length > 0) {
					ref = ref.where('hospitalName', '==', hospitalNameFilter);
				}
				await ref.orderBy(orderBy, 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;

							if (isSettledFilter != null) {
								if (isSettledFilter == true) {
									if (itemInfo.isSettled == true) {
										itemList.push(itemInfo);
									}
								}
								else {
									if (itemInfo.isSettled != true) {
										itemList.push(itemInfo);
									}
								}
							}
							else {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},/*
			loadPharmacyWorks: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacyWorks')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},*/
			loadPharmacyWork: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			onPharmacyWork: function (id, onSnapshot) {
				return firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id).onSnapshot(onSnapshot);
			},
			addPharmacyWork: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('pharmacyWorks')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPharmacyWork: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.update(model);
			},
			deletePharmacyWork: async function (id) {
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.delete();
			},
			setPharmacyWork: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.set(model);
			},
			onPharmacyProducts: function (onSnapshot) {
				return firebase.firestore()
					.collection('pharmacyProducts')
					.onSnapshot(onSnapshot);
			},
			loadPharmacyProducts: async function (pharmacyId) {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacyProducts')
					.where('pharmacyId', '==', pharmacyId)
					.orderBy('orderPriority', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacyProduct: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('pharmacyProducts')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPharmacyProduct: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('pharmacyProducts')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPharmacyProduct: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacyProducts')
					.doc(id)
					.update(model);
			},
			deletePharmacyProduct: async function (id) {
				await firebase.firestore()
					.collection('pharmacyProducts')
					.doc(id)
					.delete();
			},
			setPharmacyProduct: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacyProducts')
					.doc(id)
					.set(model);
			},
			editPharmacyProductOrders: async function (items) {
				await firebase.firestore().runTransaction(async (transaction) => {
					for (let i = 0; i < items.length; i++) {
						let ref = firebase.firestore()
							.collection('pharmacyProducts')
							.doc(items[i].id);
						transaction.update(ref, items[i]);
					}
				});
			},
			onAdminNotices: function (onSnapshot) {
				return firebase.firestore()
					.collection('adminNotices')
					.where('adminNoticeTarget', '==', 'AdminNoticeTarget.pharmacy')
					.orderBy('createAt', 'desc')
					.onSnapshot(onSnapshot);
			},
			onNewAdminNotices: function (readList, onSnapshot) {
				let ref = firebase.firestore()
					.collection('adminNotices')
					.where('adminNoticeTarget', '==', 'AdminNoticeTarget.pharmacy');
				if ((readList != null) && (readList != undefined)) {
					ref = ref.where('id', 'not-in', readList)
						.orderBy('id', 'asc');
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadAdminNotices: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('adminNotices')
					.where('adminNoticeTarget', '==', 'AdminNoticeTarget.pharmacy')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadAdminNotice: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addAdminNotice: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('adminNotices')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editAdminNotice: async function (id, model) {
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.update(model);
			},
			deleteAdminNotice: async function (id) {
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.delete();
			},
			setAdminNotice: async function (id, model) {
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.set(model);
			},

			onQnas: function (onSnapshot) {
				return firebase.firestore()
					.collection('qnas')
					.onSnapshot(onSnapshot);
			},
			onNewQnas: function (id, readQnaAnswers, onSnapshot) {
				let ref = firebase.firestore()
					.collection('qnas')
					.where('questionerType', '==', 'QuestionerType.pharmacy')
					.where('pharmacyId', '==', id);
				if ((readQnaAnswers != null) && (readQnaAnswers != undefined)) {
					ref = ref.where('id', 'not-in', readQnaAnswers);
				}
				return ref.orderBy('id', 'asc')
					.onSnapshot(onSnapshot);
			},
			loadQnas: async function (id) {
				let itemList = [];
				await firebase.firestore()
					.collection('qnas')
					.where('questionerType', '==', 'QuestionerType.pharmacy')
					.where('pharmacyId', '==', id)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadQna: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addQna: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('qnas')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editQna: async function (id, model) {
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.update(model);
			},
			deleteQna: async function (id) {
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.delete();
			},
			setQna: async function (id, model) {
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.set(model);
			},
			onPharmacists: function (pharmacyId, onSnapshot) {
				return firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.onSnapshot(onSnapshot);
			},
			loadPharmacists: async function (pharmacyId) {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacist: async function (pharmacyId, id) {
				let item = null;
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPharmacist: async function (pharmacyId, model) {
				let newDocRef = firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPharmacist: async function (pharmacyId, id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc(id)
					.update(model);
			},
			deletePharmacist: async function (pharmacyId, id) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc(id)
					.delete();
			},
			setPharmacist: async function (pharmacyId, id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc(id)
					.set(model);
			},
			addQuickInfo: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('quickInfos')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
				return newDocRef.id;
			},
			loadQuickInfo: async function (pharmacyWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('quickInfos')
					.where('pharmacyWorkId', '==', pharmacyWorkId)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onNotifications: function (onSnapshot) {
				return firebase.firestore()
					.collection('notifications')
					.onSnapshot(onSnapshot);
			},
			loadNotifications: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('notifications')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadNotification: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('notifications')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addNotification: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('notifications')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editNotification: async function (id, model) {
				await firebase.firestore()
					.collection('notifications')
					.doc(id)
					.update(model);
			},
			deleteNotification: async function (id) {
				await firebase.firestore()
					.collection('notifications')
					.doc(id)
					.delete();
			},
			setNotification: async function (id, model) {
				await firebase.firestore()
					.collection('notifications')
					.doc(id)
					.set(model);
			},
			onAdminConfigs: function (onSnapshot) {
				return firebase.firestore()
					.collection('adminConfigs')
					.onSnapshot(onSnapshot);
			},
			loadAdminConfigs: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('adminConfigs')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadAdminConfig: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addAdminConfig: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('adminConfigs')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editAdminConfig: async function (id, model) {
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.update(model);
			},
			deleteAdminConfig: async function (id) {
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.delete();
			},
			setAdminConfig: async function (id, model) {
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.set(model);
			}, onUsers: function (onSnapshot) {
				return firebase.firestore()
					.collection('users')
					.onSnapshot(onSnapshot);
			},
			loadUsers: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('users')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadUser: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addUser: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('users')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editUser: async function (id, model) {
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.update(model);
			},
			deleteUser: async function (id) {
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.delete();
			},
			setUser: async function (id, model) {
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.set(model);
			},
			onPayments: function (onSnapshot) {
				return firebase.firestore()
					.collection('payments')
					.onSnapshot(onSnapshot);
			},
			loadPayments: async function (hospitalId, hospitalWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('payments')
					.where('pharmacyId', '==', hospitalId)
					.where('pharmacyWorkId', '==', hospitalWorkId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPayment: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPayment: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('payments')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
				return addModel.id;
			},
			editPayment: async function (id, model) {
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.update(model);
			},
			deletePayment: async function (id) {
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.delete();
			},
			setPayment: async function (id, model) {
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.set(model);
			},
			saveStatistics: async function () {
				let now = new Date();
				let dayDocId = moment(now).format("YYYYMMDD");
				let yearDocId = moment(now).format("YYYY");

				await firebase.firestore().runTransaction(async (transaction) => {
					let dayDocRef = firebase.firestore()
						.collection('pharmacyStatistics')
						.doc('pharmacyStatistics')
						.collection('dayStatistics')
						.doc(dayDocId);

					let yearDocRef = firebase.firestore()
						.collection('pharmacyStatistics')
						.doc('pharmacyStatistics')
						.collection('yearStatistics')
						.doc(yearDocId);

					return transaction.get(dayDocRef).then(async (dayDoc) => {
						await transaction.get(yearDocRef).then(async (yearDoc) => {
							let dayKey = now.getHours() + '';
							let dayUpdateData = {};
							if (dayDoc.exists) {
								let dayData = dayDoc.data();
								if ((dayData[dayKey] != null) && (dayData[dayKey] != undefined)) {
									dayUpdateData[dayKey] = dayData[dayKey] + 1;
								}
								else {
									dayUpdateData[dayKey] = 1;
								}
								dayUpdateData['sum'] = dayData['sum'] + 1;
								transaction.update(dayDocRef, dayUpdateData);
							}
							else {
								dayUpdateData['createAt'] = firebase.firestore.Timestamp.fromDate(now);
								dayUpdateData['sum'] = 1;
								dayUpdateData[dayKey] = 1;
								transaction.set(dayDocRef, dayUpdateData);
							}

							let yearKey = now.getMonth() + 1 + '';
							let yearUpdateData = {};
							if (yearDoc.exists) {
								let yearData = yearDoc.data();
								if ((yearData[yearKey] != null) && (yearData[yearKey] != undefined)) {
									yearUpdateData[yearKey] = yearData[yearKey] + 1;
								}
								else {
									yearUpdateData[yearKey] = 1;
								}
								transaction.update(yearDocRef, yearUpdateData);
							}
							else {
								yearUpdateData['createAt'] = firebase.firestore.Timestamp.fromDate(now);
								yearUpdateData[yearKey] = 1;
								transaction.set(yearDocRef, yearUpdateData);
							}
						});
					});
				});
			},
			onNotificationsFromStartDate: function (pharmacyId, startDate, onSnapshot) {
				return firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('notifications')
					.where('createAt', '>=', startDate)
					.onSnapshot(onSnapshot);
			},
			onPopups: function (onSnapshot) {
				return firebase.firestore()
					.collection('popups')
					.onSnapshot(onSnapshot);
			},
			loadPopups: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('popups')
					.where('sites', 'array-contains', '약국웹')
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPopup: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPopup: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('popups')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPopup: async function (id, model) {
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.update(model);
			},
			deletePopup: async function (id) {
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.delete();
			},
			setPopup: async function (id, model) {
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.set(model);
			},
			loadHospital: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitals')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPharmacyWorkUpdateLog: async function (pharmacyWorkId, model) {
				let newDocRef = firebase.firestore()
					.collection('pharmacyWorks')
					.doc(pharmacyWorkId)
					.collection('pharmacyWorkUpdateLogs')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			loadHospitalWork: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			findBusinessNumber: async function (businessNumber) {
				let result = false;
				await firebase.firestore()
					.collection('pharmacys')
					.where('businessNumber', '==', businessNumber)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot.docs.length > 0) {
							result = true;
						}
					});
				return result;
			},
			onTempHolidays: function (onSnapshot) {
				return firebase.firestore()
					.collection('tempHolidays')
					.onSnapshot(onSnapshot);
			},
			loadTempHolidays: async function (pharmacyId, limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('tempHolidays')
					.where('pharmacyId', '==', pharmacyId)
					.orderBy('date', 'asc');
				if (limit != null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadTempHoliday: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addTempHoliday: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('tempHolidays')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editTempHoliday: async function (id, model) {
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.update(model);
			},
			deleteTempHoliday: async function (id) {
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.delete();
			},
			setTempHoliday: async function (id, model) {
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.set(model);
			},
			onManuals: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('manuals')
					.orderBy((orderBy != null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter != null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt != null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore != null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit != null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadManuals: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('manuals')
					.where('type', '==', 'ManualType.pharmacy');
				if (limit != null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadManual: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addManual: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('manuals')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editManual: async function (id, model) {
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.update(model);
			},
			deleteManual: async function (id) {
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.delete();
			},
			setManual: async function (id, model) {
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.set(model);
			},
		}
	}
};

Vue.use(database);
